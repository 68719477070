import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";


const About = () => {
    return (
        <>
            <section className="section about">
                <Container fluid="xl">
                    <Row>
                        <Col lg={5}>
                            <div className="about-img">
                                <img src="../assets/images/about-1.jpg" className="img-fluid" alt="about-1" />
                                <img src="../assets/images/about-2.jpg" className="img-fluid img2" data-aos="fade-left" alt="about-2" />
                            </div>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }}>
                            <div className="about-cont">
                                <h2 className="text-uppercase mb-3">About <span className="">Astron Global</span></h2>
                                <h5 className="text-uppercase mb-4">25+ YEARS IN granite BUSINESS</h5>
                                <p className="running-text">Astron Global was established nearly two decades ago by <strong>Ravi Shetty</strong> and <strong>A.K.Saravanan,</strong> who have 25 years of experience in the granite industry. We are exporters of quality <strong>Indian granite monuments</strong> and can supply a wide range of standard and special designs in a variety of colors. We export to several countries such as <strong>Australia, Canada, Germany, Ireland, Newzealand, USA and UK.</strong> We are customer centric in our approach and therefore able to provide excellent service.</p>
                                <div className="btns mt-4">
                                    <Button href="/Company" variant="primary">Know More</Button>
                                    <Button href="/Contact" variant="outline dark">Contact Us</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default About;
