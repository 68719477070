import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ScrollToTop from "react-scroll-to-top";
import { Icon } from '@iconify/react';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <App />
    <ScrollToTop smooth component={<Icon icon="ph:arrow-up-bold" />} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
