import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import ProductItem from './ProductItem';
import { products } from '../products';

const PopularDesigns = ({type}) => {
    /* const pageDatas = contents;
    const popularDesignDatas = pageDatas.popularDesigns; */
    //const popularDesignDatas =[];
    const popularDesign =()=>{
        const content = []
        products[type].map((product,index)=>{
            content.push(<ProductItem type={type} data={product}/>)
        })
        return content
    }
    return (
        <>
        <section className="section popular border-top">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className="heading text-center mb-5">
                            <h2>Popular Designs</h2>
                            <p>If you need further assistance or have any specific details to include, don't hesitate to ask!</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <OwlCarousel className="owl-theme" items={5} margin={15} autoplay={true} nav={false} loop dots responsiveClass={true} responsive={{
                            '0': { items: 2 },
                            '768': { items: 3 },
                            '990': { items: 5 },
                        }}>
                            {popularDesign()}                            
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
};

export default PopularDesigns;
