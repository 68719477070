import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';

const Testimonials = () => {
  return (
    <>
      <section className="banner">
        <Carousel fade controls={true} autoslide={true}>
          <Carousel.Item className="banner-bg bbg1">
            <Container className="">
              <Row className="justify-content-center banner-caption">
                <Col lg="auto" md="auto">
                  <h2 className="mb-2" data-aos="fade-down">Quality</h2>
                  <p className="mb-4">granite monuments from India</p>
                  <div className="btns">
                    <Button href="/Company" variant="primary">Explore</Button>
                    <Button href="/contact-us" variant="outline">Contact Us</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item className="banner-bg bbg2">
            <Container className="">
              <Row className="justify-content-center banner-caption">
                <Col lg="auto" md="auto">
                  <h2 className="mb-2">Delivery</h2>
                  <p className="mb-4">prompt delivery to your place</p>
                  <div className="btns">
                    <Button href="/Company" variant="primary">Explore</Button>
                    <Button href="/contact-us" variant="outline">Contact Us</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default Testimonials;
