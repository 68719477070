import React from "react";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const ProductItem = ({data,type}) =>{
    return <div className="product">
        <div className="p-img">
            <img src={`../assets/images/products/${type}/${data.modelno}.jpg`} alt={data.modelno} />
            <a href={`../assets/images/products/${type}/${data.modelno}.jpg`} className="img-zoom cwa-lightbox-image" data-desc={data.modelno}>
                <Icon icon="lucide:zoom-in" />
            </a>
        </div>
        <p className="modal-no"><span>Model No.</span><label className="p-id">{data.modelno}</label></p>
        <Link target="_blank" to={`https://api.whatsapp.com/send/?phone=919840299626&text=I am interested to get a quote on this model *${data.modelno}*`} className="p-enquiry"><img src="../assets/images/icons/whatsapp-icon.svg" alt="" /><span>Get a Quote</span></Link>
    </div>
}

export default ProductItem;