import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductItem from "./ProductItem";
import { products } from '../products';

const Products = ({type, pagetype}) =>{
    const getProductList =()=>{
        const content = []  
        // eslint-disable-next-line array-callback-return
        products[type].map((data,index) => {
          if((pagetype==='home' && index < 15) || pagetype===undefined) {
            content.push(<Col key={index} >
              <ProductItem data={data} type={type} />
            </Col>)
          }
        })
        return content
    }

    return <Row className="product-list row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 g-3 justify-content-center">
        {getProductList()}
    </Row>
}

export default Products;