
export const products = {
    populardesigns: [
        { modelno: 'AG-11016' },
        { modelno: 'AG-11017' },
        { modelno: 'AG-11018' },
        { modelno: 'AG-11019' },
        { modelno: 'AG-11020' },
        { modelno: 'AG-11022' },
        { modelno: 'AG-11024' },
        { modelno: 'AG-11026' },
        { modelno: 'AG-11033' },
        { modelno: 'AG-11034' }
    ],
    newarrivals: [
        { modelno: 'AG-11001' },
        { modelno: 'AG-11002' },
        { modelno: 'AG-11003' },
        { modelno: 'AG-11004' },
        { modelno: 'AG-11005' },
        { modelno: 'AG-11006' },
        { modelno: 'AG-11007' },
        { modelno: 'AG-11008' },
        { modelno: 'AG-11009' },
        { modelno: 'AG-11010' },
        { modelno: 'AG-11011' },
        { modelno: 'AG-11012' },
        { modelno: 'AG-11013' },
        { modelno: 'AG-11014' },
        { modelno: 'AG-11015' },
        { modelno: 'AG-11016' },
        { modelno: 'AG-11017' },
        { modelno: 'AG-11018' },
        { modelno: 'AG-11019' },
        { modelno: 'AG-11020' },
        { modelno: 'AG-11022' },
        { modelno: 'AG-11024' },
        { modelno: 'AG-11026' },
        { modelno: 'AG-11033' },
        { modelno: 'AG-11034' },
        { modelno: 'AG-11035' },
        { modelno: 'AG-11036' },
        { modelno: 'AG-11037' },
        { modelno: 'AG-11038' },
        { modelno: 'AG-11039' },
        { modelno: 'AG-11040' },
        { modelno: 'AG-11041' },
        { modelno: 'AG-11042' },
        { modelno: 'AG-11043' },
        { modelno: 'AG-11044' },
        { modelno: 'AG-11045' },
        { modelno: 'AG-11046' },
        { modelno: 'AG-11047' },
        { modelno: 'AG-11048' },
        { modelno: 'AG-11049' },
        { modelno: 'AG-11050' },
        { modelno: 'AG-11051' },
        { modelno: 'AG-11052' },
        { modelno: 'AG-11053' },
        { modelno: 'AG-11054' },
        { modelno: 'AG-11055' },
        { modelno: 'AG-11056' },
        { modelno: 'AG-11057' },
        { modelno: 'AG-11058' },
        { modelno: 'AG-11059' },
        { modelno: 'AG-11060' },
        { modelno: 'AG-11061' },
        { modelno: 'AG-11062' },
        { modelno: 'AG-11064' },
        { modelno: 'AG-11065' },
        { modelno: 'AG-11066' },
        { modelno: 'AG-11067' },
        { modelno: 'AG-11068' },
        { modelno: 'AG-11069' },
        { modelno: 'AG-11070' },
        { modelno: 'AG-11071' },
        { modelno: 'AG-11072' },
        { modelno: 'AG-11073' },
        { modelno: 'AG-11074' },
        { modelno: 'AG-11075' },
        { modelno: 'AG-11076' },
        { modelno: 'AG-11077' },
        { modelno: 'AG-11078' },
        { modelno: 'AG-11079' },
        { modelno: 'AG-11080' },
        { modelno: 'AG-11081' },
        { modelno: 'AG-11082' },
        { modelno: 'AG-11083' },
        { modelno: 'AG-11084' },
        { modelno: 'AG-11085' },
        { modelno: 'AG-11086' },
        { modelno: 'AG-11087' },
        { modelno: 'AG-11088' },
        { modelno: 'AG-11089' },
        { modelno: 'AG-11090' },
        { modelno: 'AG-11091' },
        { modelno: 'AG-11092' },
        { modelno: 'AG-11093' },
        { modelno: 'AG-11094' },
        { modelno: 'AG-11095' },
        { modelno: 'AG-11096' },
        { modelno: 'AG-11097' },
        { modelno: 'AG-11098' },
        { modelno: 'AG-11099' },
        { modelno: 'AG-11100' },
        { modelno: 'AG-11101' },
        { modelno: 'AG-11102' },
        { modelno: 'AG-11103' },
        { modelno: 'AG-11104' },
        { modelno: 'AG-11105' },
        { modelno: 'AG-11106' },
        { modelno: 'AG-11107' },
        { modelno: 'AG-11108' },
        { modelno: 'AG-11110' },
        { modelno: 'AG-11111' },
        { modelno: 'AG-11112' },
        { modelno: 'AG-11113' },
        { modelno: 'AG-11114' },
        { modelno: 'AG-11118' },
        { modelno: 'AG-11119' },
        { modelno: 'AG-11120' },
        { modelno: 'AG-11121' },
        { modelno: 'AG-11122' },
        { modelno: 'AG-11123' },
        { modelno: 'AG-11125' },
        { modelno: 'AG-11126' },
        { modelno: 'AG-11127' },
        { modelno: 'AG-11128' },
        { modelno: 'AG-11129' },
        { modelno: 'AG-11130' },
        { modelno: 'AG-11131' },
        { modelno: 'AG-11132' },
        { modelno: 'AG-11133' },
        { modelno: 'AG-11134' },
        { modelno: 'AG-11135' },
        { modelno: 'AG-11136' },
        { modelno: 'AG-11137' },
        { modelno: 'AG-11138' },
        { modelno: 'AG-11139' },
        { modelno: 'AG-11140' },
        { modelno: 'AG-11141' },
        { modelno: 'AG-11142' },
        { modelno: 'AG-11143' },
        { modelno: 'AG-11144' },
        { modelno: 'AG-11145' }

    ],
    australia:[
        { modelno: 'AG-04' },
        { modelno: 'AG-313' },
        { modelno: 'AG-359' },
        { modelno: 'AG-362' },
        { modelno: 'AG-387' },
        { modelno: 'AG-2413' },
        { modelno: 'AG-2415' },
        { modelno: 'AG-2417' },
        { modelno: 'AG-9500' },
        { modelno: 'AG-9502' },
        { modelno: 'AG-9503' },
        { modelno: 'AG-9504' },
        { modelno: 'AG-9505' },
        { modelno: 'AG-9506' },
        { modelno: 'AG-9509' },
        { modelno: 'AG-9510' },
        { modelno: 'AG-9511' },
        { modelno: 'AG-9513' },
        { modelno: 'AG-9514' },
        { modelno: 'AG-9515' },
        { modelno: 'AG-9516' },
        { modelno: 'AG-9517' },
        { modelno: 'AG-9518' },
        { modelno: 'AG-9519' },
        { modelno: 'AG-9520' },
        { modelno: 'AG-9521' },
        { modelno: 'AG-9522' },
        { modelno: 'AG-9523' },
        { modelno: 'AG-9524' },
        { modelno: 'AG-9525' },
        { modelno: 'AG-9526' },
        { modelno: 'AG-9527' },
        { modelno: 'AG-9528' },
        { modelno: 'AG-9529' },
        { modelno: 'AG-9530' },
        { modelno: 'AG-9531' },
        { modelno: 'AG-9532' },
        { modelno: 'AG-9533' },
        { modelno: 'AG-9534' },
        { modelno: 'AG-9535' },
        { modelno: 'AG-9536' },
        { modelno: 'AG-9537' },
        { modelno: 'AG-9538' },
        { modelno: 'AG-9539' },
        { modelno: 'AG-9541' },
        { modelno: 'AG-9542' },
        { modelno: 'AG-9543' },
        { modelno: 'AG-9544' }
    ],
    canada:[
        { modelno: 'AG-15' },
        { modelno: 'AG-50' },
        { modelno: 'AG-53' },
        { modelno: 'AG-72' },
        { modelno: 'AG-110' },
        { modelno: 'AG-148' },
        { modelno: 'AG-164' },
        { modelno: 'AG-170' },
        { modelno: 'AG-176' },
        { modelno: 'AG-204' },
        { modelno: 'AG-206' },
        { modelno: 'AG-223' },
        { modelno: 'AG-255' },
        { modelno: 'AG-269' },
        { modelno: 'AG-274' },
        { modelno: 'AG-316' },
        { modelno: 'AG-363' },
        { modelno: 'AG-436' },
        { modelno: 'AG-449' },
        { modelno: 'AG-472' },
        { modelno: 'AG-473' },
        { modelno: 'AG-476' },
        { modelno: 'AG-481' },
        { modelno: 'AG-492' },
        { modelno: 'AG-493' },
        { modelno: 'AG-513' },
        { modelno: 'AG-538' },
        { modelno: 'AG-574' },
        { modelno: 'AG-618' },
        { modelno: 'AG-640' },
        { modelno: 'AG-648' },
        { modelno: 'AG-652' },
        { modelno: 'AG-700' },
        { modelno: 'AG-702' },
        { modelno: 'AG-703' },
        { modelno: 'AG-704' },
        { modelno: 'AG-709' },
        { modelno: 'AG-721' },
        { modelno: 'AG-725' },
        { modelno: 'AG-757' },
        { modelno: 'AG-810' },
        { modelno: 'AG-873' },
        { modelno: 'AG-3902' },
        { modelno: 'AG-3905' },
        { modelno: 'AG-3908' },
        { modelno: 'AG-4191' },
        { modelno: 'AG-5033' },
        { modelno: 'AG-7004' },
        { modelno: 'AG-7005' },
        { modelno: 'AG-7007' },
        { modelno: 'AG-7009' },
        { modelno: 'AG-7014' },
        { modelno: 'AG-7017' },
        { modelno: 'AG-7018' },
        { modelno: 'AG-7020' },
        { modelno: 'AG-7021' },
        { modelno: 'AG-7022' },
        { modelno: 'AG-7030' },
        { modelno: 'AG-7031' },
        { modelno: 'AG-10008' },
        { modelno: 'AG-10025' },
        { modelno: 'AG-10027' },
        { modelno: 'AG-10030' },
        { modelno: 'AG-10036' },
        { modelno: 'AG-10059' },
        { modelno: 'AG-10060' },
        { modelno: 'AG-10072' },
        { modelno: 'AG-10078' },
        { modelno: 'AG-10095' },
        { modelno: 'AG-10110' },
        { modelno: 'AG-10111' },
        { modelno: 'AG-10117' },
        { modelno: 'AG-10119' },
        { modelno: 'AG-10122' },
        { modelno: 'AG-10123' },
        { modelno: 'AG-10127' },
        { modelno: 'AG-10129' },
        { modelno: 'AG-10136' },
        { modelno: 'AG-10137' },
        { modelno: 'AG-10138' },
        { modelno: 'AG-10139' },
        { modelno: 'AG-10142' },
        { modelno: 'AG-10144' },
        { modelno: 'AG-10145' },
        { modelno: 'AG-10146' },
        { modelno: 'AG-10148' },
        { modelno: 'AG-10149' },
        { modelno: 'AG-10150' },
        { modelno: 'AG-10151' },
        { modelno: 'AG-10152' },
        { modelno: 'AG-10153' },
        { modelno: 'AG-10154' },
        { modelno: 'AG-10155' },
        { modelno: 'AG-10165' },
        { modelno: 'AG-10505' },
        { modelno: 'AG-10506' },
        { modelno: 'AG-10507' },
        { modelno: 'AG-10508' },
        { modelno: 'AG-10509' },
        { modelno: 'AG-10510' },
        { modelno: 'AG-10514' },
        { modelno: 'AG-10525' },
        { modelno: 'AG-10526' },
        { modelno: 'AG-10535' },
        { modelno: 'AG-10544' },
        { modelno: 'AG-10551' },
        { modelno: 'AG-10559' },
        { modelno: 'AG-10561' },
        { modelno: 'AG-10562' },
        { modelno: 'AG-10563' },
        { modelno: 'AG-10564' },
        { modelno: 'AG-10565' },
        { modelno: 'AG-10571' },
        { modelno: 'AG-10572' },
        { modelno: 'AG-10591' },
        { modelno: 'AG-10592' },
        { modelno: 'AG-10601' },
        { modelno: 'AG-10602' },
        { modelno: 'AG-10603' },
        { modelno: 'AG-10604' },
        { modelno: 'AG-10605' },
        { modelno: 'AG-10606' },
        { modelno: 'AG-10607' },
        { modelno: 'AG-10608' },
        { modelno: 'AG-10609' },
        { modelno: 'AG-10610' },
        { modelno: 'AG-10611' },
        { modelno: 'AG-10612' },
        { modelno: 'AG-10613' },
        { modelno: 'AG-10614' },
        { modelno: 'AG-10615' },
        { modelno: 'AG-10616' },
        { modelno: 'AG-10617' },
        { modelno: 'AG-10618' },
        { modelno: 'AG-10619' },
        { modelno: 'AG-10620' },
        { modelno: 'AG-10621' },
        { modelno: 'AG-10622' },
        { modelno: 'AG-10623' },
        { modelno: 'AG-10624' },
        { modelno: 'AG-10625' },
        { modelno: 'AG-10626' },
        { modelno: 'AG-10627' },
        { modelno: 'AG-10628' },
        { modelno: 'AG-10629' },
        { modelno: 'AG-10630' },
        { modelno: 'AG-10631' },
        { modelno: 'AG-10632' },
        { modelno: 'AG-10633' },
        { modelno: 'AG-10636' },
        { modelno: 'AG-10637' },
        { modelno: 'AG-10638' },
        { modelno: 'AG-10639' },
        { modelno: 'AG-10640' },
        { modelno: 'AG-10641' },
        { modelno: 'AG-10642' },
        { modelno: 'AG-10643' },
        { modelno: 'AG-10644' },
        { modelno: 'AG-10645' },
        { modelno: 'AG-10646' },
        { modelno: 'AG-10647' },
        { modelno: 'AG-10648' },
        { modelno: 'AG-10650' },
        { modelno: 'AG-10651' },
        { modelno: 'AG-10652' },
        { modelno: 'AG-10653' },
        { modelno: 'AG-10654' },
        { modelno: 'AG-10655' },
        { modelno: 'AG-10656' },
        { modelno: 'AG-10657' },
        { modelno: 'AG-10658' },
        { modelno: 'AG-10659' },
        { modelno: 'AG-10660' },
        { modelno: 'AG-10661' },
        { modelno: 'AG-10662' },
        { modelno: 'AG-10663' },
        { modelno: 'AG-10664' },
        { modelno: 'AG-10665' },
        { modelno: 'AG-10666' },
        { modelno: 'AG-10667' },
        { modelno: 'AG-10668' },
        { modelno: 'AG-10669' },
        { modelno: 'AG-10670' },
        { modelno: 'AG-10671' },
        { modelno: 'AG-10672' },
        { modelno: 'AG-10673' },
        { modelno: 'AG-10674' },
        { modelno: 'AG-10675' },
        { modelno: 'AG-10676' },
        { modelno: 'AG-10677' },
        { modelno: 'AG-10678' },
        { modelno: 'AG-10679' },
        { modelno: 'AG-10680' },
        { modelno: 'AG-10681' },
        { modelno: 'AG-10682' },
        { modelno: 'AG-10683' },
        { modelno: 'AG-10684' },
        { modelno: 'AG-10685' },
        { modelno: 'AG-10686' },
        { modelno: 'AG-10687' },
        { modelno: 'AG-10697' },
        { modelno: 'AG-10698' },
        { modelno: 'AG-10699' },
        { modelno: 'AG-10700' },
        { modelno: 'AG-10703' },
        { modelno: 'AG-10711' },
        { modelno: 'AG-10713' },
        { modelno: 'AG-10714' },
        { modelno: 'AG-10715' },
        { modelno: 'AG-10716' },
        { modelno: 'AG-10717' },
        { modelno: 'AG-10718' },
        { modelno: 'AG-10719' },
        { modelno: 'AG-10720' },
        { modelno: 'AG-10721' },
        { modelno: 'AG-10722' },
        { modelno: 'AG-10726' },
        { modelno: 'AG-10728' },
        { modelno: 'AG-10730' },
        { modelno: 'AG-10733' },
        { modelno: 'AG-10734' },
        { modelno: 'AG-10735' },
        { modelno: 'AG-10736' },
        { modelno: 'AG-10737' },
        { modelno: 'AG-10738' },
        { modelno: 'AG-10739' },
        { modelno: 'AG-10740' },
        { modelno: 'AG-10741' },
        { modelno: 'AG-10742' },
        { modelno: 'AG-10743' },
        { modelno: 'AG-10744' },
        { modelno: 'AG-10745' },
        { modelno: 'AG-10746' },
        { modelno: 'AG-10747' },
        { modelno: 'AG-10748' },
        { modelno: 'AG-10749' },
        { modelno: 'AG-10750' },
        { modelno: 'AG-10751' },
        { modelno: 'AG-10752' },
        { modelno: 'AG-10753' },
        { modelno: 'AG-10754' },
        { modelno: 'AG-10755' },
        { modelno: 'AG-10756' },
        { modelno: 'AG-10757' },
        { modelno: 'AG-10758' },
        { modelno: 'AG-10759' },
        { modelno: 'AG-10991' },
        { modelno: 'AG-10994' },
        { modelno: 'AG-10995' }
    ],
    germany:[
        { modelno: 'AG-71' },
        { modelno: 'AG-90' },
        { modelno: 'AG-115' },
        { modelno: 'AG-116' },
        { modelno: 'AG-176' },
        { modelno: 'AG-206' },
        { modelno: 'AG-207' },
        { modelno: 'AG-278' },
        { modelno: 'AG-303' },
        { modelno: 'AG-310' },
        { modelno: 'AG-603' },
        { modelno: 'AG-648' },
        { modelno: 'AG-757' },
        { modelno: 'AG-812' },
        { modelno: 'AG-835' },
        { modelno: 'AG-880' },
        { modelno: 'AG-894' },
        { modelno: 'AG-899' },
        { modelno: 'AG-908' },
        { modelno: 'AG-939' },
        { modelno: 'AG-1213' },
        { modelno: 'AG-1233' },
        { modelno: 'AG-1238' },
        { modelno: 'AG-1240' },
        { modelno: 'AG-1268' },
        { modelno: 'AG-1269' },
        { modelno: 'AG-1270' },
        { modelno: 'AG-1275' },
        { modelno: 'AG-1276' },
        { modelno: 'AG-1332' },
        { modelno: 'AG-1355' },
        { modelno: 'AG-1362' },
        { modelno: 'AG-1370' },
        { modelno: 'AG-1395' },
        { modelno: 'AG-1403' },
        { modelno: 'AG-1422' },
        { modelno: 'AG-1423' },
        { modelno: 'AG-1446' },
        { modelno: 'AG-1448' },
        { modelno: 'AG-1450' },
        { modelno: 'AG-5052' },
        { modelno: 'AG-8000' },
        { modelno: 'AG-8001' },
        { modelno: 'AG-8002' },
        { modelno: 'AG-8003' },
        { modelno: 'AG-8004' },
        { modelno: 'AG-8005' },
        { modelno: 'AG-8006' },
        { modelno: 'AG-8007' },
        { modelno: 'AG-10170' },
        { modelno: 'AG-10171' },
        { modelno: 'AG-10172' },
        { modelno: 'AG-10173' },
        { modelno: 'AG-10174' },
        { modelno: 'AG-10175' },
        { modelno: 'AG-10176' },
        { modelno: 'AG-10177' },
        { modelno: 'AG-10178' },
        { modelno: 'AG-10179' },
        { modelno: 'AG-10180' },
        { modelno: 'AG-10181' },
        { modelno: 'AG-10182' },
        { modelno: 'AG-10183' },
        { modelno: 'AG-10184' },
        { modelno: 'AG-10185' },
        { modelno: 'AG-10186' },
        { modelno: 'AG-10187' },
        { modelno: 'AG-10188' },
        { modelno: 'AG-10189' },
        { modelno: 'AG-10190' },
        { modelno: 'AG-10191' },
        { modelno: 'AG-10192' },
        { modelno: 'AG-10193' },
        { modelno: 'AG-10194' },
        { modelno: 'AG-10195' },
        { modelno: 'AG-10196' },
        { modelno: 'AG-10197' },
        { modelno: 'AG-10198' },
        { modelno: 'AG-10199' },
        { modelno: 'AG-10200' },
        { modelno: 'AG-10201' },
        { modelno: 'AG-10202' },
        { modelno: 'AG-10203' },
        { modelno: 'AG-10204' },
        { modelno: 'AG-10205' },
        { modelno: 'AG-10206' },
        { modelno: 'AG-10207' },
        { modelno: 'AG-10208' },
        { modelno: 'AG-10209' },
        { modelno: 'AG-10210' },
        { modelno: 'AG-10211' },
        { modelno: 'AG-10212' },
        { modelno: 'AG-10213' },
        { modelno: 'AG-10214' },
        { modelno: 'AG-10215' },
        { modelno: 'AG-10216' },
        { modelno: 'AG-10217' },
        { modelno: 'AG-10218' },
        { modelno: 'AG-10219' },
        { modelno: 'AG-10220' },
        { modelno: 'AG-10221' },
        { modelno: 'AG-10223' },
        { modelno: 'AG-10224' },
        { modelno: 'AG-10225' },
        { modelno: 'AG-10226' },
        { modelno: 'AG-10227' },
        { modelno: 'AG-10228' },
        { modelno: 'AG-10229' },
        { modelno: 'AG-10230' },
        { modelno: 'AG-10231' },
        { modelno: 'AG-10332' },
        { modelno: 'AG-10485' },
        { modelno: 'AG-10486' },
        { modelno: 'AG-10487' },
        { modelno: 'AG-10488' },
        { modelno: 'AG-10489' },        
        { modelno: 'AG-10490' },
        { modelno: 'AG-10491' },
        { modelno: 'AG-10492' },
        { modelno: 'AG-10493' },
        { modelno: 'AG-10494' },
        { modelno: 'AG-10495' },
        { modelno: 'AG-10496' },
        { modelno: 'AG-10497' },
        { modelno: 'AG-10498' },
        { modelno: 'AG-10499' },
        { modelno: 'AG-10501' },
        { modelno: 'AG-10502' },
        { modelno: 'AG-10503' },
        { modelno: 'AG-10504' },
        { modelno: 'AG-10505' },
        { modelno: 'AG-10506' },
        { modelno: 'AG-10507' },
        { modelno: 'AG-10508' },
        { modelno: 'AG-10509' },
        { modelno: 'AG-10510' },
        { modelno: 'AG-10511' },
        { modelno: 'AG-10701' },
        { modelno: 'AG-10703' },
        { modelno: 'AG-10706' },
        { modelno: 'AG-10707' },
        { modelno: 'AG-10710' },
        { modelno: 'AG-10711' },
        { modelno: 'AG-10712' },
        { modelno: 'AG-10713' },
        { modelno: 'AG-10714' },
        { modelno: 'AG-10717' },
        { modelno: 'AG-10718' },
        { modelno: 'AG-10727' },
        { modelno: 'AG-10734' },
        { modelno: 'AG-10735' },
        { modelno: 'AG-10743' },
        { modelno: 'AG-10746' },
        { modelno: 'AG-10747' },
        { modelno: 'AG-10759' },
        { modelno: 'AG-10760' },
        { modelno: 'AG-10762' },
        { modelno: 'AG-10764' },
        { modelno: 'AG-10767' },
        { modelno: 'AG-10776' },
        { modelno: 'AG-10783' },
        { modelno: 'AG-10785' },
        { modelno: 'AG-10789' },
        { modelno: 'AG-10790' },
        { modelno: 'AG-10791' },
        { modelno: 'AG-10792' },
        { modelno: 'AG-10795' },
        { modelno: 'AG-10796' },
        { modelno: 'AG-10797' },
        { modelno: 'AG-10798' },
        { modelno: 'AG-10802' },
        { modelno: 'AG-10805' },
        { modelno: 'AG-10808' },
        { modelno: 'AG-10813' },
        { modelno: 'AG-10815' },
        { modelno: 'AG-10843' },
        { modelno: 'AG-10844' },
        { modelno: 'AG-10847' },
        { modelno: 'AG-10849' },
        { modelno: 'AG-10850' },
        { modelno: 'AG-10854' },
        { modelno: 'AG-10859' },
        { modelno: 'AG-10860' },
        { modelno: 'AG-10861' },
        { modelno: 'AG-10862' },
        { modelno: 'AG-10863' },
        { modelno: 'AG-10864' },
        { modelno: 'AG-10865' },
        { modelno: 'AG-10883' },
        { modelno: 'AG-10884' },
        { modelno: 'AG-10885' },
        { modelno: 'AG-10886' },
        { modelno: 'AG-10887' },
        { modelno: 'AG-10888' },
        { modelno: 'AG-10890' },
        { modelno: 'AG-10891' },
        { modelno: 'AG-10892' },
        { modelno: 'AG-10893' },
        { modelno: 'AG-10898' },
        { modelno: 'AG-10899' },
        { modelno: 'AG-10901' },
        { modelno: 'AG-10902' },
        { modelno: 'AG-10907' },
        { modelno: 'AG-10908' },
        { modelno: 'AG-10909' },
        { modelno: 'AG-10910' },
        { modelno: 'AG-10911' },
        { modelno: 'AG-10915' },
        { modelno: 'AG-10916' },
        { modelno: 'AG-10930' },
        { modelno: 'AG-10931' },
        { modelno: 'AG-10932' },
        { modelno: 'AG-10933' },
        { modelno: 'AG-10934' },
        { modelno: 'AG-10935' },
        { modelno: 'AG-10936' },
        { modelno: 'AG-10937' },
        { modelno: 'AG-10938' },
        { modelno: 'AG-10939' },
        { modelno: 'AG-10940' },
        { modelno: 'AG-10941' },
        { modelno: 'AG-10942' },
        { modelno: 'AG-10943' },
        { modelno: 'AG-10944' },
        { modelno: 'AG-10945' },
        { modelno: 'AG-10946' },
        { modelno: 'AG-10947' },
        { modelno: 'AG-10948' },
        { modelno: 'AG-10949' },
        { modelno: 'AG-10950' },
        { modelno: 'AG-10951' },
        { modelno: 'AG-10952' },
        { modelno: 'AG-10953' },
        { modelno: 'AG-10954' },
        { modelno: 'AG-10955' },
        { modelno: 'AG-10959' },
        { modelno: 'AG-10960' },
        { modelno: 'AG-10961' },
        { modelno: 'AG-10962' },
        { modelno: 'AG-10965' },
        { modelno: 'AG-11037' },
        { modelno: 'AG-11038' }
    ],
    ireland:[
        { modelno: 'AG-252' },
        { modelno: 'AG-300' },
        { modelno: 'AG-1190' },
        { modelno: 'AG-1404' },
        { modelno: 'AG-2143' },
        { modelno: 'AG-2300' },
        { modelno: 'AG-2301' },
        { modelno: 'AG-2302' },
        { modelno: 'AG-2303' },
        { modelno: 'AG-2304' },
        { modelno: 'AG-2305' },
        { modelno: 'AG-2306' },
        { modelno: 'AG-2307' },
        { modelno: 'AG-2308' },
        { modelno: 'AG-2309' },
        { modelno: 'AG-2310' },
        { modelno: 'AG-2311' },
        { modelno: 'AG-2312' },
        { modelno: 'AG-2313' },
        { modelno: 'AG-2314' },
        { modelno: 'AG-2315' },
        { modelno: 'AG-2316' },
        { modelno: 'AG-2400' },
        { modelno: 'AG-2401' },
        { modelno: 'AG-2402' },
        { modelno: 'AG-2404' },
        { modelno: 'AG-2405' },
        { modelno: 'AG-2406' },
        { modelno: 'AG-2407' },
        { modelno: 'AG-2408' },
        { modelno: 'AG-2409' },
        { modelno: 'AG-2410' },
        { modelno: 'AG-2411' },
        { modelno: 'AG-2412' },
        { modelno: 'AG-2413' },
        { modelno: 'AG-2414' },
        { modelno: 'AG-2415' },
        { modelno: 'AG-2416' },
        { modelno: 'AG-2417' },
        { modelno: 'AG-2418' },
        { modelno: 'AG-2419' },
        { modelno: 'AG-2420' },
        { modelno: 'AG-2427' },
        { modelno: 'AG-2440' },
        { modelno: 'AG-2441' },
        { modelno: 'AG-2444' },
        { modelno: 'AG-2453' },
        { modelno: 'AG-2457' },
        { modelno: 'AG-2458' },
        { modelno: 'AG-2459' },
        { modelno: 'AG-2460' },
        { modelno: 'AG-2461' },
        { modelno: 'AG-2462' },
        { modelno: 'AG-2463' },
        { modelno: 'AG-2464' },
        { modelno: 'AG-2465' },
        { modelno: 'AG-2466' },
        { modelno: 'AG-2467' },
        { modelno: 'AG-2468' },
        { modelno: 'AG-3765' },
        { modelno: 'AG-4521' },
        { modelno: 'AG-10232' },
        { modelno: 'AG-10233' },
        { modelno: 'AG-10234' },
        { modelno: 'AG-10235' },
        { modelno: 'AG-10236' },
        { modelno: 'AG-10237' },
        { modelno: 'AG-10238' },
        { modelno: 'AG-10240' },
        { modelno: 'AG-10241' },
        { modelno: 'AG-10242' },
        { modelno: 'AG-10244' },
        { modelno: 'AG-10245' },
        { modelno: 'AG-10247' },
        { modelno: 'AG-10250' },
        { modelno: 'AG-10251' },
        { modelno: 'AG-10254' },
        { modelno: 'AG-10257' },
        { modelno: 'AG-10260' },
        { modelno: 'AG-10262' },
        { modelno: 'AG-10263' },
        { modelno: 'AG-10264' },
        { modelno: 'AG-10265' },
        { modelno: 'AG-10268' },
        { modelno: 'AG-10269' },
        { modelno: 'AG-10272' },
        { modelno: 'AG-10273' },
        { modelno: 'AG-10274' },
        { modelno: 'AG-10275' },
        { modelno: 'AG-10276' },
        { modelno: 'AG-10277' },
        { modelno: 'AG-10278' },
        { modelno: 'AG-10279' },
        { modelno: 'AG-10280' },
        { modelno: 'AG-10281' },
        { modelno: 'AG-10282' },
        { modelno: 'AG-10283' },
        { modelno: 'AG-10284' },
        { modelno: 'AG-10285' },
        { modelno: 'AG-10286' },
        { modelno: 'AG-10289' },
        { modelno: 'AG-10290' },
        { modelno: 'AG-10291' },
        { modelno: 'AG-10292' },
        { modelno: 'AG-10293' },
        { modelno: 'AG-10297' },
        { modelno: 'AG-10299' },
        { modelno: 'AG-10300' },
        { modelno: 'AG-10301' },
        { modelno: 'AG-10302' },
        { modelno: 'AG-10304' },
        { modelno: 'AG-10308' },
        { modelno: 'AG-10426' },
        { modelno: 'AG-10514' },
        { modelno: 'AG-10515' },
        { modelno: 'AG-10516' },
        { modelno: 'AG-10517' },
        { modelno: 'AG-10518' },
        { modelno: 'AG-10519' },
        { modelno: 'AG-10520' },
        { modelno: 'AG-10521' },
        { modelno: 'AG-10523' },
        { modelno: 'AG-10527' },
        { modelno: 'AG-10528' },
        { modelno: 'AG-10529' },
        { modelno: 'AG-10534' },
        { modelno: 'AG-10535' },
        { modelno: 'AG-10536' },
        { modelno: 'AG-10537' },
        { modelno: 'AG-10541' },
        { modelno: 'AG-10542' },
        { modelno: 'AG-10543' },
        { modelno: 'AG-10544' },
        { modelno: 'AG-10546' },
        { modelno: 'AG-10547' },
        { modelno: 'AG-10549' },
        { modelno: 'AG-10551' },
        { modelno: 'AG-10552' },
        { modelno: 'AG-10555' },
        { modelno: 'AG-10560' },
        { modelno: 'AG-10561' },
        { modelno: 'AG-10566' },
        { modelno: 'AG-10569' },
        { modelno: 'AG-10570' },
        { modelno: 'AG-10571' },
        { modelno: 'AG-10572' },
        { modelno: 'AG-10573' },
        { modelno: 'AG-10574' },
        { modelno: 'AG-10575' },
        { modelno: 'AG-10579' },
        { modelno: 'AG-10580' },
        { modelno: 'AG-10581' },
        { modelno: 'AG-10582' },
        { modelno: 'AG-10583' },
        { modelno: 'AG-10584' },
        { modelno: 'AG-10586' },
        { modelno: 'AG-10590' },
        { modelno: 'AG-10591' },
        { modelno: 'AG-10594' },
        { modelno: 'AG-10595' },
        { modelno: 'AG-10596' },
        { modelno: 'AG-10704' },
        { modelno: 'AG-10705' },
        { modelno: 'AG-10709' },
        { modelno: 'AG-10714' },
        { modelno: 'AG-10715' },
        { modelno: 'AG-10716' },
        { modelno: 'AG-10718' },
        { modelno: 'AG-10719' },
        { modelno: 'AG-10720' },
        { modelno: 'AG-10721' },
        { modelno: 'AG-10722' },
        { modelno: 'AG-10723' },
        { modelno: 'AG-10725' },
        { modelno: 'AG-10726' },
        { modelno: 'AG-10727' },
        { modelno: 'AG-10728' },
        { modelno: 'AG-10729' },
        { modelno: 'AG-10730' },
        { modelno: 'AG-10731' },
        { modelno: 'AG-10732' },
        { modelno: 'AG-10733' },
        { modelno: 'AG-10734' },
        { modelno: 'AG-10735' },
        { modelno: 'AG-10736' },
        { modelno: 'AG-10737' },
        { modelno: 'AG-10738' },
        { modelno: 'AG-10739' },
        { modelno: 'AG-10742' },
        { modelno: 'AG-10744' },
        { modelno: 'AG-10745' },
        { modelno: 'AG-10748' },
        { modelno: 'AG-10749' },
        { modelno: 'AG-10750' },
        { modelno: 'AG-10751' },
        { modelno: 'AG-10752' },
        { modelno: 'AG-10753' },
        { modelno: 'AG-10754' },
        { modelno: 'AG-10755' },
        { modelno: 'AG-10756' },
        { modelno: 'AG-10757' },
        { modelno: 'AG-10758' },
        { modelno: 'AG-10761' },
        { modelno: 'AG-10763' },
        { modelno: 'AG-10765' },
        { modelno: 'AG-10766' },
        { modelno: 'AG-10768' },
        { modelno: 'AG-10769' },
        { modelno: 'AG-10770' },
        { modelno: 'AG-10772' },
        { modelno: 'AG-10774' },
        { modelno: 'AG-10775' },
        { modelno: 'AG-10777' },
        { modelno: 'AG-10778' },
        { modelno: 'AG-10779' },
        { modelno: 'AG-10781' },
        { modelno: 'AG-10784' },
        { modelno: 'AG-10787' },
        { modelno: 'AG-10788' },
        { modelno: 'AG-10799' },
        { modelno: 'AG-10800' },
        { modelno: 'AG-10801' },
        { modelno: 'AG-10802' },
        { modelno: 'AG-10804' },
        { modelno: 'AG-10805' },
        { modelno: 'AG-10809' },
        { modelno: 'AG-10817' },
        { modelno: 'AG-10818' },
        { modelno: 'AG-10820' },
        { modelno: 'AG-10822' },
        { modelno: 'AG-10824' },
        { modelno: 'AG-10826' },
        { modelno: 'AG-10827' },
        { modelno: 'AG-10828' },
        { modelno: 'AG-10829' },
        { modelno: 'AG-10831' },
        { modelno: 'AG-10832' },
        { modelno: 'AG-10835' },
        { modelno: 'AG-10838' },
        { modelno: 'AG-10842' },
        { modelno: 'AG-10848' },
        { modelno: 'AG-10852' },
        { modelno: 'AG-10853' },
        { modelno: 'AG-10856' },
        { modelno: 'AG-10858' },
        { modelno: 'AG-10862' },
        { modelno: 'AG-10866' },
        { modelno: 'AG-10871' },
        { modelno: 'AG-10872' },
        { modelno: 'AG-10876' },
        { modelno: 'AG-10877' },
        { modelno: 'AG-10878' },
        { modelno: 'AG-10879' },
        { modelno: 'AG-10880' },
        { modelno: 'AG-10882' },
        { modelno: 'AG-10895' },
        { modelno: 'AG-10896' },
        { modelno: 'AG-10904' },
        { modelno: 'AG-10914' },
        { modelno: 'AG-10918' },
        { modelno: 'AG-10919' },
        { modelno: 'AG-10920' },
        { modelno: 'AG-10927' },
        { modelno: 'AG-10963' },
        { modelno: 'AG-10964' },
        { modelno: 'AG-10966' },
        { modelno: 'AG-10967' },
        { modelno: 'AG-10968' },
        { modelno: 'AG-10969' },
        { modelno: 'AG-10971' },
        { modelno: 'AG-10972' },
        { modelno: 'AG-10973' },
        { modelno: 'AG-10974' },
        { modelno: 'AG-10975' },
        { modelno: 'AG-10976' },
        { modelno: 'AG-10978' },
        { modelno: 'AG-10979' },
        { modelno: 'AG-10980' },
        { modelno: 'AG-10981' },
        { modelno: 'AG-10982' },
        { modelno: 'AG-10983' }
    ],
    newzealand:[
        { modelno: 'AG-36' },
        { modelno: 'AG-90' },
        { modelno: 'AG-249' },
        { modelno: 'AG-302' },
        { modelno: 'AG-303' },
        { modelno: 'AG-304' },
        { modelno: 'AG-305' },
        { modelno: 'AG-306' },
        { modelno: 'AG-320' },
        { modelno: 'AG-353' },
        { modelno: 'AG-477' },
        { modelno: 'AG-478' },
        { modelno: 'AG-479' },
        { modelno: 'AG-895' },
        { modelno: 'AG-897' },
        { modelno: 'AG-902' },
        { modelno: 'AG-904' },
        { modelno: 'AG-1052' },
        { modelno: 'AG-1246' },
        { modelno: 'AG-1785' },
        { modelno: 'AG-1791' },
        { modelno: 'AG-1793' },
        { modelno: 'AG-1812' },
        { modelno: 'AG-1832' },
        { modelno: 'AG-1846' },
        { modelno: 'AG-1908' },
        { modelno: 'AG-2062' },
        { modelno: 'AG-2068' },
        { modelno: 'AG-2072' },
        { modelno: 'AG-2090' },
        { modelno: 'AG-2154' },
        { modelno: 'AG-2197' },
        { modelno: 'AG-2198' },
        { modelno: 'AG-2199' },
        { modelno: 'AG-2200' },
        { modelno: 'AG-2249' },
        { modelno: 'AG-2250' },
        { modelno: 'AG-2253' },
        { modelno: 'AG-2296' },
        { modelno: 'AG-2297' },
        { modelno: 'AG-2400' },
        { modelno: 'AG-2403' },
        { modelno: 'AG-2421' },
        { modelno: 'AG-2422' },
        { modelno: 'AG-4800' },
        { modelno: 'AG-5000' },
        { modelno: 'AG-7018' },
        { modelno: 'AG-9013' },
        { modelno: 'AG-9014' },
        { modelno: 'AG-10311' },
        { modelno: 'AG-10312' },
        { modelno: 'AG-10313' },
        { modelno: 'AG-10314' },
        { modelno: 'AG-10315' },
        { modelno: 'AG-10316' },
        { modelno: 'AG-10317' },
        { modelno: 'AG-10318' },
        { modelno: 'AG-10319' },
        { modelno: 'AG-10320' },
        { modelno: 'AG-10321' },
        { modelno: 'AG-10322' },
        { modelno: 'AG-10323' },
        { modelno: 'AG-10324' },
        { modelno: 'AG-10325' },
        { modelno: 'AG-10326' },
        { modelno: 'AG-10327' },
        { modelno: 'AG-10328' },
        { modelno: 'AG-10329' },
        { modelno: 'AG-10330' },
        { modelno: 'AG-10331' },
        { modelno: 'AG-10333' },
        { modelno: 'AG-10334' },
        { modelno: 'AG-10335' },
        { modelno: 'AG-10336' },
        { modelno: 'AG-10337' },
        { modelno: 'AG-10338' },
        { modelno: 'AG-10339' },
        { modelno: 'AG-10340' },
        { modelno: 'AG-10341' },
        { modelno: 'AG-10342' },
        { modelno: 'AG-10343' },
        { modelno: 'AG-10344' },
        { modelno: 'AG-10345' },
        { modelno: 'AG-10346' },
        { modelno: 'AG-10347' },
        { modelno: 'AG-10348' },
        { modelno: 'AG-10349' },
        { modelno: 'AG-10350' },
        { modelno: 'AG-10351' },
        { modelno: 'AG-10352' },
        { modelno: 'AG-10353' },
        { modelno: 'AG-10354' },
        { modelno: 'AG-10355' },
        { modelno: 'AG-10356' },
        { modelno: 'AG-10357' },
        { modelno: 'AG-10358' },
        { modelno: 'AG-10359' },
        { modelno: 'AG-10360' },
        { modelno: 'AG-10361' },
        { modelno: 'AG-10362' },
        { modelno: 'AG-10363' },
        { modelno: 'AG-10364' },
        { modelno: 'AG-10365' },
        { modelno: 'AG-10366' },
        { modelno: 'AG-10367' },
        { modelno: 'AG-10368' },
        { modelno: 'AG-10369' },
        { modelno: 'AG-10370' },
        { modelno: 'AG-10371' },
        { modelno: 'AG-10372' },
        { modelno: 'AG-10373' },
        { modelno: 'AG-10374' },
        { modelno: 'AG-10375' },
        { modelno: 'AG-10376' },
        { modelno: 'AG-10377' },
        { modelno: 'AG-10378' },
        { modelno: 'AG-10379' },
        { modelno: 'AG-10380' },
        { modelno: 'AG-10381' },
        { modelno: 'AG-10382' },
        { modelno: 'AG-10383' },
        { modelno: 'AG-10384' },
        { modelno: 'AG-10485' },
        { modelno: 'AG-10486' },
        { modelno: 'AG-10487' },
        { modelno: 'AG-10488' },
        { modelno: 'AG-10489' },
        { modelno: 'AG-10490' },
        { modelno: 'AG-10491' },
        { modelno: 'AG-10492' },
        { modelno: 'AG-10493' },
        { modelno: 'AG-10494' },
        { modelno: 'AG-10495' },
        { modelno: 'AG-10702' },
        { modelno: 'AG-10707' },
        { modelno: 'AG-10724' },
        { modelno: 'AG-10740' },
        { modelno: 'AG-10741' },
        { modelno: 'AG-10780' },
        { modelno: 'AG-10782' },
        { modelno: 'AG-10786' },
        { modelno: 'AG-10794' },
        { modelno: 'AG-10806' },
        { modelno: 'AG-10807' },
        { modelno: 'AG-10808' },
        { modelno: 'AG-10811' },
        { modelno: 'AG-10812' },
        { modelno: 'AG-10813' },
        { modelno: 'AG-10814' },
        { modelno: 'AG-10833' },
        { modelno: 'AG-10834' },
        { modelno: 'AG-10837' },
        { modelno: 'AG-10839' },
        { modelno: 'AG-10840' },
        { modelno: 'AG-10841' },
        { modelno: 'AG-10845' },
        { modelno: 'AG-10846' },
        { modelno: 'AG-10851' },
        { modelno: 'AG-10867' },
        { modelno: 'AG-10868' },
        { modelno: 'AG-10869' },
        { modelno: 'AG-10873' },
        { modelno: 'AG-10881' },
        { modelno: 'AG-10905' },
        { modelno: 'AG-10906' },
        { modelno: 'AG-10912' },
        { modelno: 'AG-10913' },
        { modelno: 'AG-10917' },
        { modelno: 'AG-10925' },
        { modelno: 'AG-10929' },
        { modelno: 'AG-10997' },
        { modelno: 'AG-10998' },
        { modelno: 'AG-10999' },
        { modelno: 'AG-11006' },
        { modelno: 'AG-11009' },
        { modelno: 'AG-11010' },
        { modelno: 'AG-11014' },
        { modelno: 'AG-11015' },
        { modelno: 'AG-11016' },
        { modelno: 'AG-11019' },
        { modelno: 'AG-11020' },
        { modelno: 'AG-11022' },
        { modelno: 'AG-11041' },
        { modelno: 'AG-11043' },
        { modelno: 'AG-11044' },
        { modelno: 'AG-11050' },
        { modelno: 'AG-11055' },
        { modelno: 'AG-11057' },
        { modelno: 'AG-11059' },
        { modelno: 'AG-11061' },
        { modelno: 'AG-11064' },
        { modelno: 'AG-11065' },
        { modelno: 'AG-11069' },
        { modelno: 'AG-11082' },
        { modelno: 'AG-11083' },
        { modelno: 'AG-11084' },
        { modelno: 'AG-11085' },
        { modelno: 'AG-11086' },
        { modelno: 'AG-11087' },
        { modelno: 'AG-11088' },
        { modelno: 'AG-11089' },
        { modelno: 'AG-11090' },
        { modelno: 'AG-11091' },
        { modelno: 'AG-11092' },
        { modelno: 'AG-11093' },
        { modelno: 'AG-11094' },
        { modelno: 'AG-11095' },
        { modelno: 'AG-11096' },
        { modelno: 'AG-11097' },
        { modelno: 'AG-11098' },
        { modelno: 'AG-11099' },
        { modelno: 'AG-11100' },
        { modelno: 'AG-11101' },
        { modelno: 'AG-11102' },
        { modelno: 'AG-11103' },
        { modelno: 'AG-11104' },
        { modelno: 'AG-11105' },
        { modelno: 'AG-11106' },
        { modelno: 'AG-11107' },
        { modelno: 'AG-11108' },
        { modelno: 'AG-11110' },
        { modelno: 'AG-11111' },
        { modelno: 'AG-11112' },
        { modelno: 'AG-11113' },
    ],
    usa:[
        { modelno: 'AG-115' },
        { modelno: 'AG-152' },
        { modelno: 'AG-153' },
        { modelno: 'AG-162' },
        { modelno: 'AG-163' },
        { modelno: 'AG-167' },
        { modelno: 'AG-377' },
        { modelno: 'AG-423' },
        { modelno: 'AG-427' },
        { modelno: 'AG-453' },
        { modelno: 'AG-470' },
        { modelno: 'AG-561' },
        { modelno: 'AG-626' },
        { modelno: 'AG-627' },
        { modelno: 'AG-628' },
        { modelno: 'AG-658' },
        { modelno: 'AG-689' },
        { modelno: 'AG-701' },
        { modelno: 'AG-708' },
        { modelno: 'AG-736' },
        { modelno: 'AG-756' },
        { modelno: 'AG-1148' },
        { modelno: 'AG-1184' },
        { modelno: 'AG-1195' },
        { modelno: 'AG-2250' },
        { modelno: 'AG-2254' },
        { modelno: 'AG-2288' },
        { modelno: 'AG-2289' },
        { modelno: 'AG-3007' },
        { modelno: 'AG-3009' },
        { modelno: 'AG-3251' },
        { modelno: 'AG-3270' },
        { modelno: 'AG-3755' },
        { modelno: 'AG-3762' },
        { modelno: 'AG-3763' },
        { modelno: 'AG-3790' },
        { modelno: 'AG-3900' },
        { modelno: 'AG-3901' },
        { modelno: 'AG-3903' },
        { modelno: 'AG-5836' },
        { modelno: 'AG-6044' },
        { modelno: 'AG-6561' },
        { modelno: 'AG-7006' },
        { modelno: 'AG-7010' },
        { modelno: 'AG-7011' },
        { modelno: 'AG-7012' },
        { modelno: 'AG-7013' },
        { modelno: 'AG-7015' },
        { modelno: 'AG-7016' },
        { modelno: 'AG-7019' },
        { modelno: 'AG-7023' },
        { modelno: 'AG-7024' },
        { modelno: 'AG-7025' },
        { modelno: 'AG-7026' },
        { modelno: 'AG-7027' },
        { modelno: 'AG-7028' },
        { modelno: 'AG-7029' },
        { modelno: 'AG-7032' },
        { modelno: 'AG-7033' },
        { modelno: 'AG-7034' },
        { modelno: 'AG-7035' },
        { modelno: 'AG-7036' },
        { modelno: 'AG-10003' },
        { modelno: 'AG-10004' },
        { modelno: 'AG-10005' },
        { modelno: 'AG-10009' },
        { modelno: 'AG-10010' },
        { modelno: 'AG-10012' },
        { modelno: 'AG-10013' },
        { modelno: 'AG-10014' },
        { modelno: 'AG-10015' },
        { modelno: 'AG-10017' },
        { modelno: 'AG-10018' },
        { modelno: 'AG-10021' },
        { modelno: 'AG-10022' },
        { modelno: 'AG-10023' },
        { modelno: 'AG-10026' },
        { modelno: 'AG-10028' },
        { modelno: 'AG-10031' },
        { modelno: 'AG-10034' },
        { modelno: 'AG-10035' },
        { modelno: 'AG-10038' },
        { modelno: 'AG-10040' },
        { modelno: 'AG-10041' },
        { modelno: 'AG-10042' },
        { modelno: 'AG-10045' },
        { modelno: 'AG-10048' },
        { modelno: 'AG-10049' },
        { modelno: 'AG-10051' },
        { modelno: 'AG-10052' },
        { modelno: 'AG-10053' },
        { modelno: 'AG-10054' },
        { modelno: 'AG-10056' },
        { modelno: 'AG-10057' },
        { modelno: 'AG-10058' },
        { modelno: 'AG-10061' },
        { modelno: 'AG-10062' },
        { modelno: 'AG-10064' },
        { modelno: 'AG-10065' },
        { modelno: 'AG-10066' },
        { modelno: 'AG-10067' },
        { modelno: 'AG-10068' },
        { modelno: 'AG-10069' },
        { modelno: 'AG-10070' },
        { modelno: 'AG-10074' },
        { modelno: 'AG-10075' },
        { modelno: 'AG-10076' },
        { modelno: 'AG-10077' },
        { modelno: 'AG-10080' },
        { modelno: 'AG-10086' },
        { modelno: 'AG-10087' },
        { modelno: 'AG-10090' },
        { modelno: 'AG-10092' },
        { modelno: 'AG-10094' },
        { modelno: 'AG-10095' },
        { modelno: 'AG-10097' },
        { modelno: 'AG-10099' },
        { modelno: 'AG-10101' },
        { modelno: 'AG-10103' },
        { modelno: 'AG-10104' },
        { modelno: 'AG-10107' },
        { modelno: 'AG-10108' },
        { modelno: 'AG-10109' },
        { modelno: 'AG-10113' },
        { modelno: 'AG-10116' },
        { modelno: 'AG-10118' },
        { modelno: 'AG-10121' },
        { modelno: 'AG-10124' },
        { modelno: 'AG-10125' },
        { modelno: 'AG-10128' },
        { modelno: 'AG-10131' },
        { modelno: 'AG-10132' },
        { modelno: 'AG-10134' },
        { modelno: 'AG-10135' },
        { modelno: 'AG-10137' },
        { modelno: 'AG-10140' },
        { modelno: 'AG-10145' },
        { modelno: 'AG-10147' },
        { modelno: 'AG-10149' },
        { modelno: 'AG-10151' },
        { modelno: 'AG-10155' },
        { modelno: 'AG-10160' },
        { modelno: 'AG-10164' },
        { modelno: 'AG-10166' },
        { modelno: 'AG-10167' },
        { modelno: 'AG-10168' },
        { modelno: 'AG-10169' },
        { modelno: 'AG-10170' },
        { modelno: 'AG-10171' },
        { modelno: 'AG-10172' },
        { modelno: 'AG-10173' },
        { modelno: 'AG-10174' },
        { modelno: 'AG-10175' },
        { modelno: 'AG-10176' },
        { modelno: 'AG-10177' },
        { modelno: 'AG-10178' },
        { modelno: 'AG-10179' },
        { modelno: 'AG-10180' },
        { modelno: 'AG-10181' },
        { modelno: 'AG-10182' },
        { modelno: 'AG-10183' },
        { modelno: 'AG-10184' },
        { modelno: 'AG-10500' },
        { modelno: 'AG-10501' },
        { modelno: 'AG-10502' },
        { modelno: 'AG-10503' },
        { modelno: 'AG-10504' },
        { modelno: 'AG-10506' },
        { modelno: 'AG-10507' },
        { modelno: 'AG-10508' },
        { modelno: 'AG-10510' },
        { modelno: 'AG-10511' },
        { modelno: 'AG-10512' },
        { modelno: 'AG-10513' },
        { modelno: 'AG-10515' },
        { modelno: 'AG-10516' },
        { modelno: 'AG-10517' },
        { modelno: 'AG-10518' },
        { modelno: 'AG-10519' },
        { modelno: 'AG-10520' },
        { modelno: 'AG-10521' },
        { modelno: 'AG-10522' },
        { modelno: 'AG-10523' },
        { modelno: 'AG-10524' },
        { modelno: 'AG-10525' },
        { modelno: 'AG-10526' },
        { modelno: 'AG-10527' },
        { modelno: 'AG-10528' },
        { modelno: 'AG-10529' },
        { modelno: 'AG-10530' },
        { modelno: 'AG-10531' },
        { modelno: 'AG-10532' },
        { modelno: 'AG-10533' },
        { modelno: 'AG-10535' },
        { modelno: 'AG-10536' },
        { modelno: 'AG-10537' },
        { modelno: 'AG-10538' },
        { modelno: 'AG-10539' },
        { modelno: 'AG-10540' },
        { modelno: 'AG-10541' },
        { modelno: 'AG-10542' },
        { modelno: 'AG-10543' },
        { modelno: 'AG-10544' },
        { modelno: 'AG-10545' },
        { modelno: 'AG-10546' },
        { modelno: 'AG-10547' },
        { modelno: 'AG-10548' },
        { modelno: 'AG-10549' },
        { modelno: 'AG-10551' },
        { modelno: 'AG-10552' },
        { modelno: 'AG-10553' },
        { modelno: 'AG-10554' },
        { modelno: 'AG-10555' },
        { modelno: 'AG-10556' },
        { modelno: 'AG-10557' },
        { modelno: 'AG-10558' },
        { modelno: 'AG-10559' },
        { modelno: 'AG-10560' },
        { modelno: 'AG-10561' },
        { modelno: 'AG-10566' },
        { modelno: 'AG-10567' },
        { modelno: 'AG-10568' },
        { modelno: 'AG-10569' },
        { modelno: 'AG-10570' },
        { modelno: 'AG-10571' },
        { modelno: 'AG-10572' },
        { modelno: 'AG-10573' },
        { modelno: 'AG-10574' },
        { modelno: 'AG-10575' },
        { modelno: 'AG-10576' },
        { modelno: 'AG-10577' },
        { modelno: 'AG-10578' },
        { modelno: 'AG-10579' },
        { modelno: 'AG-10580' },
        { modelno: 'AG-10581' },
        { modelno: 'AG-10582' },
        { modelno: 'AG-10583' },
        { modelno: 'AG-10584' },
        { modelno: 'AG-10585' },
        { modelno: 'AG-10586' },
        { modelno: 'AG-10587' },
        { modelno: 'AG-10588' },
        { modelno: 'AG-10589' },
        { modelno: 'AG-10590' },
        { modelno: 'AG-10591' },
        { modelno: 'AG-10592' },
        { modelno: 'AG-10593' },
        { modelno: 'AG-10594' },
        { modelno: 'AG-10595' },
        { modelno: 'AG-10596' },
        { modelno: 'AG-10597' },
        { modelno: 'AG-10598' },
        { modelno: 'AG-10599' },
        { modelno: 'AG-10600' },
        { modelno: 'AG-10610' },
        { modelno: 'AG-10612' },
        { modelno: 'AG-10613' },
        { modelno: 'AG-10614' },
        { modelno: 'AG-10620' },
        { modelno: 'AG-10621' },
        { modelno: 'AG-10622' },
        { modelno: 'AG-10629' },
        { modelno: 'AG-10702' },
        { modelno: 'AG-11026' },
        { modelno: 'AG-11034' },
        { modelno: 'AG-11070' },
        { modelno: 'AG-11078' },
    ],
    unitedkingdom:[
        { modelno: 'AG-90' },
        { modelno: 'AG-901' },
        { modelno: 'AG-1132' },
        { modelno: 'AG-1133' },
        { modelno: 'AG-1259' },
        { modelno: 'AG-2105' },
        { modelno: 'AG-2175' },
        { modelno: 'AG-2177' },
        { modelno: 'AG-2211' },
        { modelno: 'AG-2231' },
        { modelno: 'AG-2246' },
        { modelno: 'AG-2247' },
        { modelno: 'AG-2248' },
        { modelno: 'AG-2249' },
        { modelno: 'AG-2251' },
        { modelno: 'AG-2252' },
        { modelno: 'AG-2253' },
        { modelno: 'AG-2254' },
        { modelno: 'AG-2255' },
        { modelno: 'AG-2256' },
        { modelno: 'AG-2259' },
        { modelno: 'AG-2260' },
        { modelno: 'AG-2263' },
        { modelno: 'AG-2264' },
        { modelno: 'AG-2265' },
        { modelno: 'AG-2266' },
        { modelno: 'AG-2267' },
        { modelno: 'AG-2268' },
        { modelno: 'AG-2457' },
        { modelno: 'AG-2459' },
        { modelno: 'AG-2462' },
        { modelno: 'AG-2503' },
        { modelno: 'AG-2504' },
        { modelno: 'AG-2506' },
        { modelno: 'AG-2508' },
        { modelno: 'AG-4490' },
        { modelno: 'AG-4520' },
        { modelno: 'AG-4533' },
        { modelno: 'AG-4596' },
        { modelno: 'AG-5008' },
        { modelno: 'AG-5065' },
        { modelno: 'AG-5074' },
        { modelno: 'AG-5075' },
        { modelno: 'AG-5076' },
        { modelno: 'AG-5077' },
        { modelno: 'AG-5078' },
        { modelno: 'AG-5079' },
        { modelno: 'AG-6000' },
        { modelno: 'AG-6001' },
        { modelno: 'AG-6002' },
        { modelno: 'AG-6003' },
        { modelno: 'AG-6004' },
        { modelno: 'AG-6005' },
        { modelno: 'AG-6006' },
        { modelno: 'AG-6007' },
        { modelno: 'AG-6008' },
        { modelno: 'AG-6009' },
        { modelno: 'AG-6010' },
        { modelno: 'AG-6011' },
        { modelno: 'AG-6012' },
        { modelno: 'AG-6013' },
        { modelno: 'AG-6014' },
        { modelno: 'AG-6015' },
        { modelno: 'AG-6016' },
        { modelno: 'AG-6017' },
        { modelno: 'AG-6018' },
        { modelno: 'AG-6019' },
        { modelno: 'AG-6020' },
        { modelno: 'AG-6021' },
        { modelno: 'AG-6022' },
        { modelno: 'AG-6023' },
        { modelno: 'AG-6024' },
        { modelno: 'AG-6025' },
        { modelno: 'AG-6026' },
        { modelno: 'AG-6027' },
        { modelno: 'AG-6028' },
        { modelno: 'AG-6029' },
        { modelno: 'AG-6030' },
        { modelno: 'AG-6042' },
        { modelno: 'AG-6043' },
        { modelno: 'AG-6044' },
        { modelno: 'AG-6563' },
        { modelno: 'AG-10385' },
        { modelno: 'AG-10386' },
        { modelno: 'AG-10387' },
        { modelno: 'AG-10388' },
        { modelno: 'AG-10389' },
        { modelno: 'AG-10390' },
        { modelno: 'AG-10391' },
        { modelno: 'AG-10392' },
        { modelno: 'AG-10393' },
        { modelno: 'AG-10394' },
        { modelno: 'AG-10395' },
        { modelno: 'AG-10396' },
        { modelno: 'AG-10397' },
        { modelno: 'AG-10398' },
        { modelno: 'AG-10399' },
        { modelno: 'AG-10400' },
        { modelno: 'AG-10401' },
        { modelno: 'AG-10402' },
        { modelno: 'AG-10403' },
        { modelno: 'AG-10404' },
        { modelno: 'AG-10405' },
        { modelno: 'AG-10406' },
        { modelno: 'AG-10407' },
        { modelno: 'AG-10408' },
        { modelno: 'AG-10409' },
        { modelno: 'AG-10410' },
        { modelno: 'AG-10411' },
        { modelno: 'AG-10412' },
        { modelno: 'AG-10413' },
        { modelno: 'AG-10414' },
        { modelno: 'AG-10415' },
        { modelno: 'AG-10416' },
        { modelno: 'AG-10417' },
        { modelno: 'AG-10418' },
        { modelno: 'AG-10419' },
        { modelno: 'AG-10420' },
        { modelno: 'AG-10421' },
        { modelno: 'AG-10422' },
        { modelno: 'AG-10423' },
        { modelno: 'AG-10424' },
        { modelno: 'AG-10425' },
        { modelno: 'AG-10426' },
        { modelno: 'AG-10427' },
        { modelno: 'AG-10428' },
        { modelno: 'AG-10429' },
        { modelno: 'AG-10430' },
        { modelno: 'AG-10431' },
        { modelno: 'AG-10432' },
        { modelno: 'AG-10433' },
        { modelno: 'AG-10434' },
        { modelno: 'AG-10435' },
        { modelno: 'AG-10436' },
        { modelno: 'AG-10438' },
        { modelno: 'AG-10439' },
        { modelno: 'AG-10440' },
        { modelno: 'AG-10441' },
        { modelno: 'AG-10442' },
        { modelno: 'AG-10443' },
        { modelno: 'AG-10444' },
        { modelno: 'AG-10445' },
        { modelno: 'AG-10446' },
        { modelno: 'AG-10447' },
        { modelno: 'AG-10448' },
        { modelno: 'AG-10449' },
        { modelno: 'AG-10450' },
        { modelno: 'AG-10451' },
        { modelno: 'AG-10452' },
        { modelno: 'AG-10453' },
        { modelno: 'AG-10454' },
        { modelno: 'AG-10455' },
        { modelno: 'AG-10456' },
        { modelno: 'AG-10457' },
        { modelno: 'AG-10458' },
        { modelno: 'AG-10459' },
        { modelno: 'AG-10460' },
        { modelno: 'AG-10461' },
        { modelno: 'AG-10462' },
        { modelno: 'AG-10463' },
        { modelno: 'AG-10464' },
        { modelno: 'AG-10465' },
        { modelno: 'AG-10466' },
        { modelno: 'AG-10467' },
        { modelno: 'AG-10468' },
        { modelno: 'AG-10469' },
        { modelno: 'AG-10470' },
        { modelno: 'AG-10471' },
        { modelno: 'AG-10472' },
        { modelno: 'AG-10474' },
        { modelno: 'AG-10475' },
        { modelno: 'AG-10476' },
        { modelno: 'AG-10477' },
        { modelno: 'AG-10478' },
        { modelno: 'AG-10479' },
        { modelno: 'AG-10480' },
        { modelno: 'AG-10481' },
        { modelno: 'AG-10482' },
        { modelno: 'AG-10483' },
        { modelno: 'AG-10708' },
        { modelno: 'AG-10719' },
        { modelno: 'AG-10720' },
        { modelno: 'AG-10721' },
        { modelno: 'AG-10722' },
        { modelno: 'AG-10768' },
        { modelno: 'AG-10773' },
        { modelno: 'AG-10793' },
        { modelno: 'AG-10803' },
        { modelno: 'AG-10810' },
        { modelno: 'AG-10816' },
        { modelno: 'AG-10819' },
        { modelno: 'AG-10821' },
        { modelno: 'AG-10823' },
        { modelno: 'AG-10830' },
        { modelno: 'AG-10836' },
        { modelno: 'AG-10857' },
        { modelno: 'AG-10870' },
        { modelno: 'AG-10874' },
        { modelno: 'AG-10875' },
        { modelno: 'AG-10897' },
        { modelno: 'AG-10900' },
        { modelno: 'AG-10903' },
        { modelno: 'AG-10921' },
        { modelno: 'AG-10922' },
        { modelno: 'AG-10923' },
        { modelno: 'AG-10924' },
        { modelno: 'AG-10926' },
        { modelno: 'AG-10927' },
        { modelno: 'AG-10956' },
        { modelno: 'AG-10957' },
        { modelno: 'AG-10958' },
        { modelno: 'AG-10970' },
        { modelno: 'AG-10982' },
        { modelno: 'AG-10983' },
        { modelno: 'AG-10984' },
        { modelno: 'AG-10986' },
        { modelno: 'AG-10988' },
        { modelno: 'AG-10989' },
        { modelno: 'AG-10990' },
        { modelno: 'AG-10991' },
        { modelno: 'AG-10993' },
        { modelno: 'AG-10994' },
        { modelno: 'AG-10996' },
        { modelno: 'AG-10999' },
        { modelno: 'AG-11001' },
        { modelno: 'AG-11002' },
        { modelno: 'AG-11003' },
        { modelno: 'AG-11004' },
        { modelno: 'AG-11005' },
        { modelno: 'AG-11007' },
        { modelno: 'AG-11008' },
        { modelno: 'AG-11011' },
        { modelno: 'AG-11012' },
        { modelno: 'AG-11013' },
        { modelno: 'AG-11017' },
        { modelno: 'AG-11018' },
        { modelno: 'AG-11019' },
        { modelno: 'AG-11020' },
        { modelno: 'AG-11024' },
        { modelno: 'AG-11033' },
        { modelno: 'AG-11035' },
        { modelno: 'AG-11036' },
        { modelno: 'AG-11037' },
        { modelno: 'AG-11039' },
        { modelno: 'AG-11040' },
        { modelno: 'AG-11042' },
        { modelno: 'AG-11045' },
        { modelno: 'AG-11046' },
        { modelno: 'AG-11047' },
        { modelno: 'AG-11048' },
        { modelno: 'AG-11049' },
        { modelno: 'AG-11051' },
        { modelno: 'AG-11052' },
        { modelno: 'AG-11053' },
        { modelno: 'AG-11054' },
        { modelno: 'AG-11056' },
        { modelno: 'AG-11058' },
        { modelno: 'AG-11060' },
        { modelno: 'AG-11062' },
        { modelno: 'AG-11067' },
        { modelno: 'AG-11068' },
        { modelno: 'AG-11071' },
        { modelno: 'AG-11073' },
        { modelno: 'AG-11074' },
        { modelno: 'AG-11075' },
        { modelno: 'AG-11076' },
        { modelno: 'AG-11077' },
        { modelno: 'AG-11079' },
        { modelno: 'AG-11081' },
        { modelno: 'AG-11082' },
        { modelno: 'AG-11083' },
        { modelno: 'AG-11084' },
        { modelno: 'AG-11085' },
        { modelno: 'AG-11086' }
    ]
};
