import { useEffect } from "react";
import {  Row, Col, Container } from "react-bootstrap";
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Features from "../components/Features";


const Materials = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />

        <section className="section inner alt-bg">
          <Container fluid>
            <Row>
                  <Col lg={12}>
                      <div className="heading text-center">
                          <h2>Wide Range of MATERIALS</h2>
                          <p>We are providing wide range of materials for your selection</p>
                      </div>
                  </Col>
              </Row>              
          </Container>
        </section>

        <section className="section">
          <Container fluid>
            <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 g-3 justify-content-center">
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Bahama-Blue.jpg" alt="Bahama-Blue" className="img-fluid" /></figure>
                  <span>Bahama Blue</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Blue-Pearl.jpg" alt="Blue-Pearl" className="img-fluid" /></figure>
                  <span>Blue Pearl</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Cats-Eye-Brown.jpg" alt="Cats-Eye-Brown" className="img-fluid" /></figure>
                  <span>Cats Eye Brown</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Cats-Eye-Red.jpg" alt="Cats-Eye-Red" className="img-fluid" /></figure>
                  <span>Cats Eye Red</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Emerald-Pearl.jpg" alt="Emerald-Pearl" className="img-fluid" /></figure>
                  <span>Emerald Pearl</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Himalaya.jpg" alt="Himalaya" className="img-fluid" /></figure>
                  <span>Himalaya</span>
                </div>
              </Col>

              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Imperial-White.jpg" alt="Imperial-White" className="img-fluid" /></figure>
                  <span>Imperial White</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/India-Black.jpg" alt="India-Black" className="img-fluid" /></figure>
                  <span>India Black</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Indian-Aurora.jpg" alt="Indian-Aurora" className="img-fluid" /></figure>
                  <span>Indian Aurora</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/indian-mahogany.jpg" alt="indian-mahogany" className="img-fluid" /></figure>
                  <span>Indian Mahogany</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Indian-Sadarali-Grey.jpg" alt="Indian-Sadarali-Grey" className="img-fluid" /></figure>
                  <span>Indian Sadarali Grey</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Indian-Sira-Grey.jpg" alt="Indian-Sira-Grey" className="img-fluid" /></figure>
                  <span>Indian Sira Grey</span>
                </div>
              </Col>

              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/India-Red.jpg" alt="India-Red" className="img-fluid" /></figure>
                  <span>India Red</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Kuppam-Green.jpg" alt="Kuppam-Green" className="img-fluid" /></figure>
                  <span>Kuppam Green</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Mist-Black.jpg" alt="Mist-Black" className="img-fluid" /></figure>
                  <span>Mist Black</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Paradiso.jpg" alt="Paradiso" className="img-fluid" /></figure>
                  <span>Paradiso</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Raw-Silk.jpg" alt="Raw-Silk" className="img-fluid" /></figure>
                  <span>Raw Silk</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Red-Multi.jpg" alt="Red-Multi" className="img-fluid" /></figure>
                  <span>Red Multi</span>
                </div>
              </Col>

              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/regal-black.jpg" alt="regal-black" className="img-fluid" /></figure>
                  <span>regal black</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Ruby-Red.jpg" alt="Ruby-Red" className="img-fluid" /></figure>
                  <span>Ruby Red</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/South-African-Grey.jpg" alt="South-African-Grey" className="img-fluid" /></figure>
                  <span>South African Grey</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Star-Galaxy.jpg" alt="Star-Galaxy" className="img-fluid" /></figure>
                  <span>Star Galaxy</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Steel-Grey.jpg" alt="Steel-Grey" className="img-fluid" /></figure>
                  <span>Steel Grey</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Tan-Brown.jpg" alt="Tan-Brown" className="img-fluid" /></figure>
                  <span>Tan Brown</span>
                </div>
              </Col>
              <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Tropical-Green.jpg" alt="Tropical-Green" className="img-fluid" /></figure>
                  <span>Tropical Green</span>
                </div>
              </Col>
              {/* <Col>
                <div className="hovershine">
                  <figure><img src="../assets/images/materials/Viscont-White.jpg" alt="Viscont-White" className="img-fluid" /></figure>
                  <span>Viscont White</span>
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>

        <Features />
        
        <Footer />
    </div>
  );
};

export default Materials;
