import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import OwlCarousel from 'react-owl-carousel';

const Testimonials = () => {
    return (
        <>
            <section className="section testimonials alt-bg">
                <Container fluid="xl">
                    <Row>
                        <Col lg={12}>
                            <div className="heading text-center mb-5">
                                <h2>What our Clients Say</h2>
                                <p>We serve customers in various countries around the world.<br />If you have any specific questions or need assistance related to a particular country, feel free to ask!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <OwlCarousel className="owl-theme" items={4} margin={15} autoplay={true} nav={false} autoHeight={true} dots responsiveClass={true} responsive={{
                                '0': { items: 1 },
                                '768': { items: 2 },
                                '990': { items: 4 },
                            }}>
                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Thank you so much. Very happy and impressed with the quality of granite and crafstmanship.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/australia.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">Australia</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Being a small retailer, I was a bit hesitant about ordering online from a faceless company in India.  Have to say I have been very impressed with the excellent communication and the speed with which my requests have been met. Keep up the good work!</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/canada.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">Canada</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>I wanted to drop a line to say how pleased I am with my first load of monuments and wish to confirm my second order. I look forward to a positive and productive relationship with you in the future!</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/germany.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">Germany</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Just took delivery of my second load and I am totally satisfied with every aspect of your products and service. The direct to door delivery is particularly appreciated. I did not expect importing monuments to be so easy.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/ireland.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">Ireland</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>We have imported more than 70 loads from Astron Global over the last 15 years. We are very pleased with the services provided by them.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/newzealand.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">New Zealand</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Astron Global offers efficient service and reasonable prices. Good value all round, especially for the small retailer. Affixing quality approved stickers on each item  is a good idea.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/usa.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">United States of America</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Of all the companies that I have been buying from, Astron Global has been the most stress free!! They respond to questions almost immediately and address problems promptly problems promptly. Definitely recommended!.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/uk.svg" alt="" className="img-fluid" />
                                        <h5 className="c-name">Client Name</h5>
                                        <p className="designation">United Kingdom</p>
                                    </div>
                                </div>

                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Testimonials;
