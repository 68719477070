import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// Add Components
import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import Features from "./Features";
import MaterialsHome from "./MaterialsHome";
import ProcessFlow from "./Process-Flow";
import PopularDesigns from "./Popular-Designs";
import Testimonials from "./Testimonials";
import Banner from "./Banner";
import Products from "./Products";

const Home = (params) => {
 /*  const constants = params.constant;
  const pageDatas = constants.home; */

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  return (
    <div>
      <Header />
      <Banner />
      <About />
      <Features />
      <section className="section products alt-bg">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="heading text-center mb-5">
                <h2>Wide Range of Designs</h2>
                <p>We offer a comprehensive range of memorials in various colors.<br />We also produce custom designs as per specifications given by the customer.</p>
              </div>
            </Col>
          </Row>
          <Products type={'newarrivals'} pagetype='home' />           
          <div className="text-center mt-5">
            <Button href="/products/new-arrivals" variant="primary">View More</Button>
          </div>
        </Container>
      </section>
      <MaterialsHome />
      <ProcessFlow />
      <PopularDesigns type={'populardesigns'} />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;