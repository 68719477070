import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const Footer = () => {
    return (
        <>
            <footer className="dark-bg">
                <Container fluid="xl">
                    <Row>
                        <Col lg={3} xs="6">
                            <div className="footer-widget">
                                <Link to="/" className="footer-logo"><img src="../assets/images/logo-light.svg" alt="astron logo" /></Link>
                                <p>Astron Global was established nearly two decades ago by Ravi Shetty and A.K.Saravanan, who have 25 years of experience in the granite industry.</p>
                                <Link to="/company" className="readmore">Know More</Link>
                            </div>
                        </Col>
                        <Col lg={3} xs="6">
                            <div className="footer-widget">
                                <h2>Useful Links</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/products/new-arrivals">Products</Link></li>
                                    <li><Link to="/materials">Materials</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} sm="6">
                            <div className="footer-widget">
                                <h2>Contact Information</h2>
                                <p className="contact-info"><span><Icon icon="ph:map-pin-line" /></span>F 47, H, First Main Road,<br />Anna Nagar, <br />Chennai - 600 102, India.</p>
                                <p className="contact-info"><span><Icon icon="ph:phone" /></span><Link to="tel:+91 9840299626">+91 9840299626</Link></p>
                                <p className="contact-info"><span><Icon icon="mage:email" /></span><Link to="mailto:info@astronglobal.com">info@astronglobal.com</Link></p>
                            </div>
                        </Col>
                        <Col lg={3} sm="6">
                            <Link to="https://wa.me/919840299626?text=Hello%20Astron%20Global,I%27m%20Interested%20On%20Your%20Products." target="_blank" className="enquiry-link">
                                <span className="blink"><img src="../assets/images/icons/whatsapp-icon.svg" alt="whatsapp-icon" /></span>
                                FOR Quick Enquiry
                                <label>+91 9840299626</label>
                            </Link>
                        </Col>
                    </Row>
                    <div className="copyright">
                        <p className="mb-0">Copyright © 2024 Astron Global. All Rights Reserved.</p>
                    </div>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
