import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Icon } from '@iconify/react';

const Features = () => {
    return (
        <>
            <section className="section features border-top">
                <Container fluid="xl">
                    <Row>
                        <Col lg={12}>
                            <div className="heading text-center mb-5">
                                <h2>Our Features</h2>
                                <p>We offer a comprehensive range of memorials in various colors.<br />We also produce custom designs as per specifications given by the customer.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="gx-0">
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="200">
                            <div className="feature-box">
                                <p>ORDER<br />SMALL QUANTITY</p>
                                <Icon icon="solar:cart-plus-broken" />
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="300">
                            <div className="feature-box alt">
                                <Icon icon="solar:box-broken" />
                                <p>EXPORT<br />PACKING</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="400">
                            <div className="feature-box">
                                <p>FULLY<br />INSURED</p>
                                <Icon icon="ant-design:safety-outlined" />
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="500">
                            <div className="feature-box alt">
                                <Icon icon="fluent:payment-32-regular" />
                                <p>EASY<br />PAYMENT TERMS</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="600">
                            <div className="feature-box">
                                <p>CUSTOM<br />DESIGN</p>
                                <Icon icon="fluent:design-ideas-24-regular" />
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6} data-aos="fade-down" data-aos-duration="700">
                            <div className="feature-box alt">
                                <Icon icon="fluent:building-factory-16-regular" />
                                <p>DIRECT<br />FROM FACTORY</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Features;
