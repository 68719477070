import { useEffect } from "react";
import {  Row, Col, Container } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { Icon } from '@iconify/react';
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProcessFlow from "../components/Process-Flow";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <div>
        <Header />

        <section className="section inner alt-bg">
          <Container fluid>
            <Row>
                <Col lg={12}>
                    <div className="heading text-center">
                        <h2>Frequently Asked Questions</h2>
                        <p>To help answer some of your questions, here is our most Frequently Asked Questions.</p>
                    </div>
                </Col>
            </Row>              
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10} md={12} sm={12}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>Can I order small quantities?</Accordion.Header>
                        <Accordion.Body>Yes, you can order small quantities.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>Can you deliver the consignment to my address?</Accordion.Header>
                        <Accordion.Body>Yes, we can.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>Is the price quoted the total cost or is there any extra charge?</Accordion.Header>
                        <Accordion.Body>The price quoted by us is the total cost of the stones delivered at your place. VAT/GST/DUTY depending on the country is extra.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>What is the mode of payment?</Accordion.Header>
                        <Accordion.Body>Payment is made by electronic wire transfer from your bank to our bank in India.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>What happens if there is damage during transit?</Accordion.Header>
                        <Accordion.Body>The consignment is fully insured against all risks.However the damaged piece will be replaced.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>How are the stones packed?</Accordion.Header>
                        <Accordion.Body>The stones are wrapped with two layers of bubble sheet,securely strapped with white strip and packed in wooden crates.The crates are labelled with details of content.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>Can you supply designs other than the designs in your brochure?</Accordion.Header>
                        <Accordion.Body>Yes, We can.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><span><Icon icon="fluent:chat-bubbles-question-32-regular" /></span>Can you provide drawings/sketches along with price quote or for the monuments ordered?</Accordion.Header>
                        <Accordion.Body>Yes we can provide drawings (CAD) free of cost.</Accordion.Body>
                    </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>

        <ProcessFlow />
        
        <Footer />
    </div>
  );
};

export default Faq;
