import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Products from "../components/Products";


const NewArrivals = () => {
  const params = new useParams();
  const type = params.type;
  const paramType = type.replace('-',' ');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header />

      <section className="section inner alt-bg">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="heading text-center mb-5">
                <h2>{paramType}</h2>
                <p>We offer a comprehensive range of memorials in various colors.<br/>We also produce custom designs as per specifications given by the customer.</p>
              </div>
            </Col>
          </Row>
          <Products type={paramType.replace(' ','')} />
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default NewArrivals;