import React from "react";
import { Nav, Navbar, Row, Col, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
// import { GoogleTranslate } from "./GoogleTranslate";
const Header = () => {
	return (
		<>
			<header className="header" id="header">
				<div className="header-top">
					<Container fluid="xl">
						<Row>
							<Col lg={6} md={6} className="d-none d-md-block">
								<p>F 47, H, First Main Road, Anna Nagar, Chennai, India.</p>
							</Col>
							{/* <GoogleTranslate/> */}
						</Row>
					</Container>
				</div>
				<Navbar expand="lg">
					<Container fluid="md">
						<Link to="/" className="navbar-brand"><img src="../assets/images/logo.svg" alt="astron logo" /></Link>
						<Link to="https://wa.me/919840299626?text=Hello%20Astron%20Global,I%27m%20Interested%20On%20Your%20Products." target="_blank" className="enquiry-link mob d-lg-none d-block">
							<span className="blink"><img src="../assets/images/icons/whatsapp-icon.svg" alt="whatsapp-icon" /></span>
							FOR Quick Enquiry
							<label>+91 9840299626</label>
						</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mx-auto navigation-links">
								<Nav.Link href="/" className="">Home</Nav.Link>
								<NavDropdown title={<span>Products <Icon icon="ep:arrow-down-bold" /></span>} id="collapsible-nav-dropdown">
									<NavDropdown.Item href="/products/new-arrivals"><span><img src="../assets/images/icons/new.svg" alt="new" /></span> New Arrivals</NavDropdown.Item>
									<NavDropdown.Item href="/products/australia"><span><img src="../assets/images/country/australia.svg" alt="australia" /></span> Australia</NavDropdown.Item>
									<NavDropdown.Item href="/products/canada"><span><img src="../assets/images/country/canada.svg" alt="canada" /></span> Canada</NavDropdown.Item>
									<NavDropdown.Item href="/products/germany"><span><img src="../assets/images/country/germany.svg" alt="Germany" /></span> Germany</NavDropdown.Item>
									<NavDropdown.Item href="/products/ireland"><span><img src="../assets/images/country/ireland.svg" alt="Ireland" /></span> Ireland</NavDropdown.Item>
									<NavDropdown.Item href="/products/new-zealand"><span><img src="../assets/images/country/newzealand.svg" alt="Newzealand" /></span> Newzealand</NavDropdown.Item>
									<NavDropdown.Item href="/products/usa"><span><img src="../assets/images/country/usa.svg" alt="USA" /></span> USA</NavDropdown.Item>
									<NavDropdown.Item href="/products/united-kingdom"><span><img src="../assets/images/country/uk.svg" alt="United Kingdom" /></span> United Kingdom</NavDropdown.Item>
								</NavDropdown>
								<Nav.Link href="/materials">Materials</Nav.Link>
								<Nav.Link href="/faq">FAQ</Nav.Link>
								<Nav.Link href="/careers">Careers</Nav.Link>
								<Nav.Link href="/contact-us">Contact Us</Nav.Link>
							</Nav>
							<Nav className="mr-auto">
								<Link to="https://wa.me/919840299626?text=Hello%20Astron%20Global,I%27m%20Interested%20On%20Your%20Products." target="_blank" className="enquiry-link d-lg-block d-none">
									<span className="blink"><img src="../assets/images/icons/whatsapp-icon.svg" alt="whatsapp-icon" /></span>
									FOR Quick Enquiry
									<label>+91 9840299626</label>
								</Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

export default Header;
