import { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";


const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const [validated, setValidated] = useState(false);
  const [formInput, setFormInputs] = useState({c_email:'',c_phonenumber:''});
  const [alertVisible, setAlertVisible] = useState(false);
  const [isLoad,setIsLoad] = useState('SEND NOW');
  const [image, setImage] = useState(null);

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePhoneNumber(mobile) {
    const re =/^[0-9]*$/;
    return re.test(String(mobile).toLowerCase());
  }

  const onFormInput = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name == 'c_uploadResume'){
      const file = e.target.files[0];
      setImage(e.target.files[0]);
    }else{
      setFormInputs(values => ({...values, [name]:value}));
    }
  }

 /*  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
 */
  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    event.preventDefault();
      event.stopPropagation();
    if (form.checkValidity() === true) {
      setIsLoad('Wait...')
      const formData = new FormData();
      formData.append('c_uploadResume',image);
      Object.keys(formInput).map((index) => {
        formData.append(index,formInput[index]);
      });
      try {
        const response = await fetch('http://localhost/send_mail/common.php', {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          setAlertVisible(response.ok)
          setIsLoad('SEND NOW')
          /* setFormInputs({c_email:'',c_phonenumber:''});
          setImage(null); */
        } else {
          setAlertVisible(false)
        }
      } catch (error) {
        setAlertVisible(false)
      }
    }
    setValidated(true);
  };

  return (
    <div>
      <Header />

      <section className="section inner alt-bg">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="heading text-center">
                <h2>Careers</h2>
                <p>Astron Global offers opportunities to persons who are willing to<br />contribute to the growth of the company.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
            <div className="border-design p-5">
              <h5 className="mb-4">Send your resume</h5>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-4">
                  <Form.Group as={Col} className="form-group" controlId="formGridName">
                    <Form.Label>Your Name <span className="req">*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Your Name" name="c_name" value={formInput.c_name || ''} onChange={onFormInput} required />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="form-group" controlId="formGridEmail">
                    <Form.Label>Email ID <span className="req">*</span></Form.Label>
                    <Form.Control type="email" placeholder="Enter Your Email" name="c_email" value={formInput.c_email || ''} onChange={onFormInput} isInvalid={formInput.c_email !='' && !validateEmail(formInput.c_email)} required />
                    <Form.Control.Feedback type="invalid">{(formInput.c_email !='' && !validateEmail(formInput.c_email))?'Email is invalid':'Required'}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} className="form-group" controlId="formGridPhone">
                    <Form.Label>Phone No. <span className="req">*</span></Form.Label>
                    <Form.Control type="tel" placeholder="Enter Phone No." name ='c_phonenumber' value={formInput.c_phonenumber || ''} onChange={onFormInput} isInvalid={formInput.c_phonenumber !='' && !validatePhoneNumber(formInput.c_phonenumber) && (formInput.c_phonenumber.length>1 && formInput.c_phonenumber.length <10)} maxLength='10' required />
                    <Form.Control.Feedback type="invalid">{(formInput.c_phonenumber !='' && !validatePhoneNumber(formInput.c_phonenumber) && (formInput.c_phonenumber.length>1 && formInput.c_phonenumber.length <10))?'Enter valid phone number':'Required'}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="form-group" controlId="formGridCity">
                    <Form.Label>Choose City <span className="req">*</span></Form.Label>
                    <Form.Select name='c_city' value={formInput.c_city || ''} onChange={onFormInput} required>
                      <option>Choose...</option>
                      <option>...</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group className="form-group" controlId="formGridMessage">
                    <Form.Label>Write Message <span className="req">*</span></Form.Label>
                    <Form.Control as="textarea" rows={4} placeholder="Write Message" name='c_writeMessage' value={formInput.c_writeMessage || ''} onChange={onFormInput} required />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="form-group mt-4" controlId="formFile">
                    <Form.Label>Upload Resume <span className="req">*</span></Form.Label>
                    <Form.Control type="file" name='c_uploadResume' onChange={onFormInput} required />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button variant="primary" type="submit">{isLoad}</Button>
              </Form>
              {alertVisible && <Alert variant="success mt-4">
                Thanks for your interest. we will get in touch with you.
              </Alert>}
            </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Testimonials />

      <Footer />
    </div>
  );
};

export default Careers;
